<template>
  <div class="rule">
    <!--  标签页-->
    <el-tabs v-model="activeName"  class="el-nav" @tab-click="handleClick">
      <el-tab-pane label="用户管理" name="user"></el-tab-pane>
      <!--      交易信息-->
      <el-tab-pane label="交易信息" name="account">
        <div class="statistical-center">
          <div class="statistical-center-list flex-nowrap">

            <div class="statistical-center-item">
              <div class="statistical-center-item-title">
                账户经验值
              </div>
              <div class="statistical-center-item-num">
                {{userDetail.account ? userDetail.account.exp : 0}}
              </div>
              <el-divider></el-divider>
              <div class="statistical-center-item-foot vertical-center">
                当前等级
                <img src="~assets/images/data/member.png" alt="">
                <span class="lv">LV.{{userDetail.account ? userDetail.account.level : 0}}</span>
              </div>
            </div>
            <div class="statistical-center-item long-item">
              <div class="statistical-center-item-title">
                账户金币
              </div>
              <div class="statistical-center-item-num">
                {{userDetail.account ? userDetail.account.gold : 0}}
              </div>
              <el-divider></el-divider>
              <div class="statistical-center-item-foot vertical-center">
                最近收入
                <el-tag><i class="el-icon-top"></i>{{goldRecord.last_increase_log ? goldRecord.last_increase_log.amount : 0}}</el-tag>
                最近支出
                <el-tag><i class="el-icon-bottom"></i>{{goldRecord.last_reduce_log ? goldRecord.last_reduce_log.amount : 0}}</el-tag>
              </div>
              <el-button type="primary" plain class="btn1" @click="setGold('increase')">转入</el-button>
              <el-button type="danger" plain  class="btn2" @click="setGold('reduce')">转出</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>

    </el-tabs>

    <div class="statistical-bottom">
      <div class="statistical-bottom-title vertical-center space-between">
        <div class="vertical-center">
          <i class="el-icon-coin"></i>
          <span class="statistical-bottom-title-text">账户金币</span>
        </div>
      </div>
      <el-divider></el-divider>

      <div class="statistical-bottom-table" v-if="goldLogData.data && goldLogData.data.length">

        <div class="table">
          <el-table
              :data="goldLogData.data"
              :header-cell-style="{background: '#F6F8F9',fontSize:'14px',fontWeight: '400',color: '#1C1F21',}"
              :cell-style="{fontSize: '14px',fontWeight: '400',color: '#71777D',}"
              stripe
              border
              style="width: 100%">
            <el-table-column
                prop="date"
                label="加减金币"
                width="170">
              <template slot-scope="scope">
                <span class="table-num" v-if="scope.row.mode === 'increase'">+{{scope.row.amount }}</span>
                <span class="table-num" v-else>-{{ scope.row.amount }}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="动作"
            >
              <template slot-scope="scope"><span class="table-num">{{ scope.row.remark.action }}</span></template>
            </el-table-column>
            <el-table-column
                prop="created_at"
                label="更新时间"
                width="170">
            </el-table-column>

          </el-table>
        </div>

        <el-pagination
            v-if="goldLogData.meta && goldLogData.meta.last_page > 1"
            class="page"
            background
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout=" prev, pager, next,total, jumper"
            :total="goldLogData.meta ? goldLogData.meta.total : 0">
        </el-pagination>

      </div>
      <empty-prompt style="margin-top: 50px;margin-bottom: 200px;" v-else></empty-prompt>
    </div>

    <!--    新增修改弹框-->
    <el-dialog
        class="dialog"
        :title="set_type === 'increase' ? '账户转入':'账户转出'"
        :visible.sync="iszrDialogVisible"
        top="calc( 50vh - 200px )"
        width="540px"
        :destroy-on-close="true"
    >

      <!--  表单-->
      <el-form label-position="right" :model="ruleForm" :rules="rules" label-width="80px" class="form" ref="ruleForm">
        <el-form-item :label="set_type === 'increase' ? '转入金币' : '转出金币'" prop="amount">
          <el-input v-model.number="ruleForm.amount"   maxlength="5" placeholder="请输入金币数"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
              type="textarea"
              :rows="4"
              maxlength="50"
              placeholder="请输入备注"
              v-model="ruleForm.remark">
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="iszrDialogVisible = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="setSubmit" v-if="set_submit_status">确 定</el-button>
        <el-button class="form-operation-item" round type="primary" :loading="true" v-else>提交中</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import EmptyPrompt from "components/EmptyPrompt";
export default {
  name: "Account",
  components:{
    EmptyPrompt
  },
  data() {
    return{
      activeName: 'account',
      iszrDialogVisible: false,
      tableData: [],
      name: '',
      uuid: '',
      goldRecord: {}, // 金币减少增加记录
      page: 1,
      pageSize: 20,
      goldLogData: [],  // 日志记录
      set_type: '', // 判断类型
      ruleForm: {
        amount: '', // 转入转出金币数量
        remark: '', // 备注
      },
      set_submit_status: true,  // 提交装填
      rules: {
        amount: [
          { required: true, message: '请输入金币数量', trigger: 'blur' },
          { type: 'number', message: '金币数量必须为数字值'}
        ],
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ]
      },
      userDetail: {}, // 获取用户信息
    }
  },
  created() {
    if(this.$route.params.uuid) {
      this.uuid= this.$route.params.uuid
      this.getUser()
      this.getAccount()
      this.getGoldRecord()
    }
  },
  methods: {
    // 获取账户信息
    getAccount() {
      let url= this.$api.Users + '/' + this.uuid + '/' + this.$api.Account;
      let data= {
        last_log: 1,
        log_type: 'gold'
      }
      this.$http.post(url, data,  true).then(res =>{
        if(res.data.success) {
          this.goldRecord= res.data.data
        }
      })
    },
    // 获取金币记录
    getGoldRecord() {
      let url= this.$api.Users + '/' + this.uuid + '/' + this.$api.accountLogs;
      let data= {
        page: this.page,
        pageSize: this.pageSize,
        type: this.type
      }
      this.$http.post(url, data, true).then(res =>{
        if(res.data.success) {
          this.goldLogData= res.data.data
        }
      })
    },
    // 获取用户信息
    getUser() {
      let url= this.$api.Users + '/' + this.uuid;
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          this.userDetail= res.data.data
        }
      })
    },
    // 转入转出操作
    setGold(type) {
      this.set_type= type
      this.ruleForm.amount= ''
      this.ruleForm.remark= ''
      this.iszrDialogVisible= true
    },
    // 金币提交
    setSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(!this.set_submit_status){
            return false
          }
          this.set_submit_status= false
          let url= this.$api.Users + '/'+ this.uuid + '/' +this.$api.changeAccount
          let data= {
            type: 'gold',
            mode: this.set_type,
            amount: this.ruleForm.amount,
            remark: this.ruleForm.remark
          }
          this.$http.post(url, data, true).then(res => {
            if(res.data.success) {
              this.$message.success('操作成功')
              setTimeout(()=>{
                window.location.reload()
              },500)
            }else {
              this.set_submit_status= true
              this.$message.error(res.data.message)
            }
          }).catch(error=>{
            this.set_submit_status= true
            this.$message.error(error.response.data.message)
          })
        } else {
          return false;
        }
      });

    },
    // 分页
    handleCurrentChange(val) {
      this.page= val
      this.getGoldRecord()
    },
    // tab切换想像
    handleClick(tab, event) {
      if(tab.name === 'user') {
        window.history.go(-1)
      }
    }

  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/user/index.less";
</style>
